import React, { Component } from 'react'
import SlidePessoas from 'components/slides/slide-pessoas/index'
import SlideVideos from 'components/slides/slide-videos'

// Components Globals
import Header from 'components/header'
import Navigation from 'components/navigation-pages'
import Footer from 'components/footer/main'
import SEO from 'components/seo'

// Style this component
import { BannerSobre, CuidadoPessoas, Encontros } from '../../Style/sobre/style'
import { Container, TitleBanner, SubtitleBanner, ButtonGreen } from '../../Style/global'

//images
import LogoIF from 'images/icone-nf.svg'
import Banner from 'images/banners/banner-sobre.jpg'

// Sass Global
import 'sass/main.scss'

class PageSobre extends Component {
  render() {
    return (
      <div className="main">
        <SEO title="Quem somos" />

        <Header />

        <Navigation />

        <BannerSobre bg={Banner}>
          <div className="container">
            <div>
              <TitleBanner>Quem somos</TitleBanner>
              <SubtitleBanner>Soluções que facilitam os processos de farmácias e drogarias</SubtitleBanner>
            </div>
            <div className="box">
              <img src={LogoIF} alt="Logo Inovafarma" className="icone" />
              <div className="cont">
                <p>
                  Nossa missão é desenvolver soluções inovadoras que simplifiquem e automatizem os processos de farmácias e drogarias,
                  permitindo o alcance aos seus objetivos.
                </p>
                <h3>Equipe e parceiros Precisão Sistemas</h3>
              </div>
            </div>
          </div>
        </BannerSobre>

        <CuidadoPessoas className="s-pessoas">
          <Container>
            <div className="flex">
              <div className="area-slide">
                <SlidePessoas />
              </div>
              <div className="texto">
                <h3>Pessoas cuidando de pessoas</h3>
                <p>
                  Por trás dos produtos e soluções existem pessoas que trabalham duro diariamente para ajudar o seu negócio a melhorar os
                  resultados.
                </p>
                <ButtonGreen>Quero utilizar na minha farmácia</ButtonGreen>
              </div>
            </div>
          </Container>
        </CuidadoPessoas>

        <Encontros className="s-encontros">
          <Container>
            <div className="cont">
              <div className="texto">
                <h2>Encontros InovaFarma</h2>
                <p>
                  Todo ano a equipe INOVAFARMA se reune para trocar experiências, aprender um com os outros e fortalecer a união do time
                  para continuar entregando a melhor solução para as farmácias e drogarias de todo o Brasil.
                </p>
              </div>
              <SlideVideos />
            </div>
          </Container>
        </Encontros>

        <Footer padding_maior={false} />
      </div>
    )
  }
}

export default PageSobre
